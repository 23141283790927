<template>
  <body class="bg-purple">
    <div class="central-body">
      <div class="image-404">
        <img src="@/assets/404/404.svg" width="800px" height="400px" />
      </div>

      <div class="msg">
        You are backing to Home page ...
        <!-- <el-button/> -->
      </div>
      <!-- <div class="btn-go-home">Home</div> -->
    </div>
    <div class="objects">
      <img class="object_rocket" src="@/assets/404/rocket.svg" width="40px" />
      <div class="earth-moon">
        <img class="object_earth" src="@/assets/404/earth.svg" width="100px" />
        <img class="object_moon" src="@/assets/404/moon.svg" width="80px" />
      </div>
      <div class="box_astronaut">
        <img
          class="object_astronaut"
          src="@/assets/404/astronaut.svg"
          width="140px"
        />
      </div>
    </div>
    <div class="glowing_stars">
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
    </div>
  </body>
</template>
<script>
import { useRouter } from "vue-router";

// import { useRouter } from 'vue-router';

export default {
  name: "404",
  setup() {
    const router = useRouter();
    setTimeout(() => {
      router.push("/");
    }, 3000);
  },
};
</script>
<style scoped>
@import "../assets/404/style.css";
.msg {
  margin-top: 100px;
  font-family: Poppins-Regular;
  font-size: 20px;
  color: #ffcb39;
}
</style>
